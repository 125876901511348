import { BasicIcons as Icons } from '../components/icons';
import { NAV_ROLES } from './roles';

export const adminRoot = '/admin';
export const eppGC = '/eppGC';

export const eppGCDrawerLinks = [
  {
    label: 'Dashboard',
    icon: Icons.Ui,
    to: `${eppGC}/dashboard`,
    rolePermission: NAV_ROLES.XPAY_GC.DASHBOARD,
  },
  {
    label: 'Companies',
    icon: Icons.BankAcct,
    to: `${eppGC}/subContractors`,
    rolePermission: NAV_ROLES.XPAY_GC.COMPANIES,
  },
  {
    label: 'My Projects',
    icon: Icons.Document,
    to: `${eppGC}/projects`,
    rolePermission: NAV_ROLES.XPAY_GC.PROJECTS,
  },
  {
    label: 'Outgoing Payments',
    icon: Icons.OutgoingPayments,
    to: `${eppGC}/payments`,
    rolePermission: NAV_ROLES.XPAY_GC.PAYMENTS,
  },
  {
    label: 'Operating Account',
    icon: Icons.OperatingAcct,
    to: `${eppGC}/operatingAccount`,
    rolePermission: NAV_ROLES.XPAY_GC.OP_ACCOUNT,
  },
  {
    label: 'Struxtion Account',
    icon: Icons.BankAcct,
    to: `${eppGC}/struxtionAccount`,
    rolePermission: NAV_ROLES.XPAY_GC.STRX_ACCOUNT,
  },
  {
    label: 'Documents',
    icon: Icons.iconDocument,
    to: `${eppGC}/documents`,
    rolePermission: NAV_ROLES.XPAY_GC.DOCUMENTS,
  },
];

export const eppSC = '/eppSC';
export const eppSCDrawerLinks = [
  {
    label: 'Dashboard',
    icon: Icons.Ui,
    to: `${eppSC}/dashboard`,
    rolePermission: [],
  },
  {
    label: 'Home',
    icon: Icons.Home,
    to: `${eppSC}/home`,
    rolePermission: [],
  },
  {
    label: 'Incoming Payments',
    icon: Icons.IncomingPayments,
    to: `${eppSC}/payments`,
    rolePermission: [],
  },
  {
    label: 'Documents',
    icon: Icons.iconDocument,
    to: `${eppSC}/documents`,
    rolePermission: [],
  },
  {
    label: 'Operating Account',
    icon: Icons.BankAcct,
    to: `${eppSC}/operatingAccount`,
    rolePermission: [],
  }, {
    label: 'Pay',
    icon: Icons.Xicon,
    to: `${eppSC}/scXPay`,
    rolePermission: [],
  },
];

export const adminDrawerLinks = [
  {
    label: 'Loans',
    icon: Icons.Ui,
    to: `${adminRoot}/loans`,
    rolePermission: [],
  },
  {
    label: 'Companies',
    icon: Icons.Office,
    to: `${adminRoot}/companies`,
    rolePermission: [],
  },
  {
    label: 'Users',
    icon: Icons.Users,
    to: `${adminRoot}/users`,
    rolePermission: [],
  },
  {
    label: 'Projects',
    icon: Icons.Document,
    to: `${adminRoot}/projects`,
    rolePermission: [],
  },
  {
    label: 'Struxtion Accounts',
    icon: Icons.BankAcct,
    to: `${adminRoot}/struxtionAccounts`,
    rolePermission: [],
  },
  {
    label: 'Operating Accounts',
    icon: Icons.OperatingAcct,
    to: `${adminRoot}/operatingAccounts`,
    rolePermission: [],
  },
  {
    label: 'Struxtion Funding',
    icon: Icons.Funding,
    to: `${adminRoot}/funding`,
    rolePermission: [],
  },
  {
    label: 'Payments',
    icon: Icons.Wallet,
    to: `${adminRoot}/payments`,
    rolePermission: [],
  },
  {
    label: 'Pay',
    icon: Icons.Xicon,
    to: `${adminRoot}/epp`,
    rolePermission: [],
  },
  {
    label: 'GC Accounts',
    icon: Icons.BankAcct,
    to: `${adminRoot}/gcAccounts`,
    rolePermission: [],
  },
  {
    label: 'XDA',
    icon: Icons.BankAcct,
    to: `${adminRoot}/xda`,
    rolePermission: [],
  },
  {
    label: 'Struxtion Revenue',
    icon: Icons.BankAcct,
    to: `${adminRoot}/revenueAccount`,
    rolePermission: [],
  },
];

export const gcRoot = '/gc';
export const gcDrawerLinks = [
  {
    label: 'Subcontractors',
    icon: Icons.Worker,
    to: `${gcRoot}/dashboard`,
    rolePermission: [],
  },
];

export const scRoot = '/sc';
export const scDrawerLinks = [
  {
    label: 'Home',
    icon: Icons.Home,
    to: `${scRoot}/home`,
    rolePermission: [],
  }, {
    label: 'Struxtion Account',
    icon: Icons.BankAcct,
    to: `${scRoot}/bank-account`,
    rolePermission: [],
  },
  {
    label: 'Operating Account',
    icon: Icons.OperatingAcct,
    to: `${scRoot}/operating-account`,
    rolePermission: [],
  },
];

export const registrationRoot = '/registration';
