import ApproverIcon from '../../assets/icons/approver.svg';
import FinalApproverIcon from '../../assets/icons/final-approver.svg';
import AdminIcon from '../../assets/icons/admin.svg';
import ExternalApproverIcon from '../../assets/icons/external-approver.svg';
import SuperAdminIcon from '../../assets/icons/super-admin.svg';
import PaymentProcessorIcon from '../../assets/icons/payment-processor.svg';
import LienWaiverAssigner from '../../assets/icons/lien-waiver-signer.svg';
import PayeeAdminRole from '../../assets/icons/payee-admin-role.svg';
import PayeeLWSRole from '../../assets/icons/payee-lws-role.svg';
import circleIcon from '../../assets/icons/check_circle_outline.svg';

export const availableRoles = [
  {
    key: 'superAdmin',
    name: 'Super Admin',
    value: '5',
    icon: SuperAdminIcon,
  },
  {
    key: 'admin',
    name: 'Payor Admin',
    value: '6',
    icon: AdminIcon,
  },
  {
    key: 'paymentProcessor',
    name: 'Payment Processor',
    value: '7',
    icon: PaymentProcessorIcon,
  },
  {
    key: 'finalApprover',
    name: 'Final Approver',
    value: '9',
    icon: FinalApproverIcon,
  },
  {
    key: 'approver',
    name: 'Approver',
    value: '8',
    icon: ApproverIcon,
  },
  /* {
    key: 'observer',
    name: 'Observer',
    value: '11',
    icon: ViewerIcon,
  }, */
  {
    key: 'externalApprover',
    name: 'External Approver',
    value: '10',
    icon: ExternalApproverIcon,
  },
  {
    key: 'projectAccountant',
    name: 'Project Accountant',
    value: '12',
    icon: ExternalApproverIcon,
  },
];

export const supplierRoles = [
  {
    key: 'main_contact',
    name: 'Main Contact',
    value: '11',
    icon: circleIcon,
  },
  {
    key: 'lien_waiver_signer',
    name: 'Lien Waiver Signer',
    value: '12',
    icon: LienWaiverAssigner,
  },
];

export const payeeRoles = [
  {
    key: 'sub_contractor',
    name: 'Payee Admin',
    value: '2',
    icon: PayeeAdminRole,
  },
  {
    key: 'lien_waiver_signer',
    name: 'Lien Waiver Signer',
    value: '13',
    icon: PayeeLWSRole,
  },
];

export const availableRolesSelect = (roles, companyId, includePayeeRoles) => {
  const available = roles.map((rol) => {
    const rolObject = {};
    let allRoles = availableRoles;
    if (includePayeeRoles === true) {
      allRoles = [...availableRoles, ...payeeRoles];
    }
    const selectedRolValue = allRoles.find((e) => e.value === rol.id.toString() && rol.companyId === companyId);
    if (selectedRolValue) {
      rolObject.value = parseInt(selectedRolValue.value, 10);
      rolObject.name = selectedRolValue.name;
    }
    return rolObject;
  });
  return available.filter((value) => Object.keys(value).length !== 0);
};

export const rolesList = [];
