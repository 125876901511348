import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography, makeStyles } from '@material-ui/core';
import SwitchCompanyModal from '../Multicompany/SwitchCompanyModal';
import { useUser } from '../../contexts/UserContext';
import ConfirmSwitchCompanyModal from '../Multicompany/ConfirmSwitchCompanyModal';
import { orderRelatedCompanies } from '../../utils';

const useStyles = makeStyles((theme) => ({
  children: {
    paddingLeft: theme.spacing(2),
  },
}));

const MulticompanyMenuItem = ({ closeMenuBar }) => {
  const classes = useStyles();
  const { user: { companyId, relatedCompanies } } = useUser();
  const [company, setCompany] = useState({});
  const [isOpenSwitchComp, setIsOpenSwitchComp] = useState(false);
  const [isOpenConfirmSwitchComp, setIsOpenConfirmSwitchComp] = useState(false);

  const handleMoreCompanies = () => {
    setIsOpenSwitchComp(true);
    closeMenuBar();
  };

  return (
    <>
      <MenuItem>Change company</MenuItem>
      {
        orderRelatedCompanies(relatedCompanies).slice(0, 3).map((c) => (
          <MenuItem disabled={companyId === c.id} onClick={() => { setIsOpenConfirmSwitchComp(true); setCompany({ name: c.name, id: c.id, companyType: c.companyType }); }} key={c.id}>
            <Typography color={companyId === c.id ? 'primary' : 'inherit'} className={classes.children} variant="body2">{c.name}</Typography>
          </MenuItem>
        ))
      }
      {
        relatedCompanies.length > 3 ? (
          <MenuItem>
            <Typography onClick={() => handleMoreCompanies()} className={classes.children} variant="body2">More companies</Typography>
          </MenuItem>
        ) : null
      }
      { isOpenSwitchComp && <SwitchCompanyModal open={isOpenSwitchComp} setModal={setIsOpenSwitchComp} /> }
      { isOpenConfirmSwitchComp && <ConfirmSwitchCompanyModal company={company} open={isOpenConfirmSwitchComp} setModal={setIsOpenConfirmSwitchComp} /> }
    </>
  );
};

MulticompanyMenuItem.propTypes = {
  closeMenuBar: PropTypes.func.isRequired,
};

export default MulticompanyMenuItem;
