import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import {
  useHistory,
  Switch,
  Route as ReactRoute,
} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import './api/axios/responseInterceptor';
import AuthError from './components/AuthError';
import {
  companyTypes, CURRENT_COMPANY_TYPE, MakePaymentsStatusEnum, UserSettings,
} from './constants';
import {
  adminRoot,
  scRoot,
  gcRoot,
  adminDrawerLinks,
  scDrawerLinks,
  gcDrawerLinks,
  registrationRoot,
  eppGC,
  eppSC,
  eppGCDrawerLinks,
  eppSCDrawerLinks,
} from './constants/navigation';
import { useKeycloak } from './contexts/KeycloakContext';
import { useUser } from './contexts/UserContext';
import {
  AppBarWithDrawer,
  AppBarWithoutDrawer,
} from './layouts/AppBarDrawer';
import Legal from './layouts/Legal';
import Registration from './layouts/Registration';
import companyTypeRedirect from './redirects/companyTypeRedirect';
import registrationRedirect from './redirects/registrationRedirect';
import {
  addRouteGroup, formatRolActionsList, loadSessionStorage, orderRelatedCompanies,
} from './utils';
import Confirmation from './components/Registration/Confirmation';
import {
  setGtagCompany,
  setGtagConfig,
  setGtagUser,
  triggerGtagEvent,
} from './utils/analytics';
import { NAV_ROLES } from './constants/roles';
import AccessRestrictedError from './components/AccessRestrictedError';
import LoadingPage from './pages/Loading';
import NotificationEvaluatorComponent from './components/Application/NotificationEvaluatorComponent';

const NotFound = lazy(() => import('./pages/NotFound'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const ElectronicAgreement = lazy(() => import('./pages/ElectronicAgreement'));
const SolidPlatformTerms = lazy(() => import('./pages/SolidPlatformTerms'));
const SolidPrivacyPolicy = lazy(() => import('./pages/SolidPrivacyPolicy'));
const SolidWebsite = lazy(() => import('./pages/SolidWebsite'));
const LCBElectronicAgreement = lazy(() => import('./pages/LCBElectronicAgreement'));
const USAPatriotActNotice = lazy(() => import('./pages/USAPatriotActNotice'));
const LCBBusinessAccountAgreement = lazy(() => import('./pages/LCBBusinessAccountAgreement'));

const SCBankAccount = lazy(() => import('./pages/Admin/SCBankAccount'));
const EGCDetail = lazy(() => import('./pages/Admin/EGCDetail'));
const EGCAssociateSubcontractors = lazy(() => import('./pages/EppGeneralContractor/EGCAssociateSubcontractors'));
const SCProfile = lazy(() => import('./pages/Admin/SCProfile'));
const GCProfile = lazy(() => import('./pages/Admin/GCProfile'));
const PayAppDetail = lazy(() => import('./pages/Admin/PayAppDetail'));
const PayAppProcessing = lazy(() => import('./pages/Admin/PayAppProcessing'));
const Loans = lazy(() => import('./pages/Admin/Loans'));
const Projects = lazy(() => import('./pages/Admin/Projects'));
const Payments = lazy(() => import('./pages/Admin/Payments'));
const StruxtionBankAccounts = lazy(() => import('./pages/Admin/StruxtionBankAccounts'));
const StruxtionBankDetails = lazy(() => import('./components/SolidFiBank/StruxtionBankAccDetails'));
const GCBankAccDetails = lazy(() => import('./components/SolidFiBank/GCBankAccDetails'));
const OperatingAccounts = lazy(() => import('./pages/Admin/OperatingAccounts'));
const StruxtionFunding = lazy(() => import('./pages/Admin/StruxtionFunding'));
const EPPs = lazy(() => import('./pages/Admin/EPPs'));
const Users = lazy(() => import('./pages/Admin/Users'));
const UserProfile = lazy(() => import('./pages/Admin/UserProfile'));
const LoanDetail = lazy(() => import('./pages/Admin/LoanDetail'));
const LoanDetailProjects = lazy(() => import('./pages/Admin/LoanDetailProjects'));
const Companies = lazy(() => import('./pages/Admin/Companies'));
const GCAcccounts = lazy(() => import('./pages/Admin/GCAccounts'));
const Default = lazy(() => import('./pages/Admin/Default'));
const AdminProjectDetails = lazy(() => import('./pages/Admin/AdminProjectDetails'));
const LoanDetailDocuments = lazy(() => import('./pages/Admin/LoanDetailDocuments'));
const LoanDetailStatements = lazy(() => import('./pages/Admin/LoanDetailStatements'));
const CompanyCreation = lazy(() => import('./pages/Admin/CompanyCreation'));
const DetailSubcontractorXpay = lazy(() => import('./pages/Admin/DetailSubcontractorXpay'));
const XDAccounts = lazy(() => import('./pages/Admin/XDA'));
const XDADetail = lazy(() => import('./pages/Admin/XDA/Detail'));
const SRAAccount = lazy(() => import('./pages/Admin/SRA'));

const GCDashboard = lazy(() => import('./pages/GeneralContractor/GCDashboard'));
const GcDefault = lazy(() => import('./pages/GeneralContractor/GcDefault'));
const GcScDetail = lazy(() => import('./pages/GeneralContractor/GcScDetail'));
const GcScPayAppDetail = lazy(() => import('./pages/GeneralContractor/GcScPayAppDetail'));
const CompanyProfile = lazy(() => import('./pages/CompanyProfile'));
const EGCProfile = lazy(() => import('./pages/EppGeneralContractor/EGCProfile'));
const EGCEditCompanyProfile = lazy(() => import('./pages/EppGeneralContractor/EGCEditCompanyProfile'));

const Dashboard = lazy(() => import('./pages/SubContractors/Dashboard'));
const CheckMl = lazy(() => import('./pages/SubContractors/CheckMl'));
const CheckClosingDocuments = lazy(() => import('./pages/SubContractors/CheckClosingDocuments'));
const CheckLienWaiver = lazy(() => import('./pages/SubContractors/CheckLienWaiver'));
const XPayPlusContract = lazy(() => import('./pages/SubContractors/XPayPlusContract'));
const Application = lazy(() => import('./pages/SubContractors/Application'));
const Documents = lazy(() => import('./pages/SubContractors/Documents'));
const Statements = lazy(() => import('./pages/SubContractors/Statements'));
const BankAccount = lazy(() => import('./pages/SubContractors/BankAccount'));
const OperatingAccount = lazy(() => import('./pages/SubContractors/OperatingAccount'));
const ScDefault = lazy(() => import('./pages/SubContractors/ScDefault'));
const ScProjectsTab = lazy(() => import('./pages/SubContractors/ScProjectsTab'));
const ProjectDetails = lazy(() => import('./pages/SubContractors/ProjectDetails'));
const ProjectCreation = lazy(() => import('./pages/SubContractors/ProjectCreation'));
const RequestAdjuments = lazy(() => import('./pages/SubContractors/RequestAdjustments'));

const AboutProjects = lazy(() => import('./components/Registration/AboutProjects'));
const AboutYou = lazy(() => import('./components/Registration/AboutYou'));
const AboutCompany = lazy(() => import('./components/Registration/AboutCompany'));
const AboutYourCustomers = lazy(() => import('./components/Registration/AboutYourCustomers'));
const AboutFinancials = lazy(() => import('./components/Registration/AboutFinancials'));

const EGCDefault = lazy(() => import('./pages/EppGeneralContractor/EGCDefault'));
const EGCDashboard = lazy(() => import('./pages/EppGeneralContractor/EGCDashboard'));
const EGCStruxtionAccount = lazy(() => import('./pages/EppGeneralContractor/EGCStruxtionAccount'));
const EGCBankAccount = lazy(() => import('./pages/EppGeneralContractor/EGCBankAccount'));
const EGCDocuments = lazy(() => import('./pages/EppGeneralContractor/EGCDocuments'));
const EGCSubcontractor = lazy(() => import('./pages/EppGeneralContractor/EGCSubcontractor'));
const EGCProjects = lazy(() => import('./pages/EppGeneralContractor/EGCProjects'));
const SCAssociatedDetail = lazy(() => import('./pages/EppGeneralContractor/SCAssociatedDetail'));
const EGCSubcontractorCreation = lazy(() => import('./pages/EppGeneralContractor/EGCSubcontractorCreation'));
const EGCProjectDetail = lazy(() => import('./pages/EppGeneralContractor/EGCProjectDetail'));
const EGCBatchPayment = lazy(() => import('./pages/EppGeneralContractor/EGCBatchPayment'));
const EGCProjectCreation = lazy(() => import('./pages/EppGeneralContractor/EGCProjectCreation'));
const EGCScDetail = lazy(() => import('./pages/EppGeneralContractor/EGCScDetail'));
const EGCEditSc = lazy(() => import('./pages/EppGeneralContractor/EGCEditSc'));
const EGCPaymentInstructionCreation = lazy(() => import('./pages/EppGeneralContractor/EGCPaymentInstructionCreation'));
const EGCPayments = lazy(() => import('./pages/EppGeneralContractor/EGCPayments'));
const EGCApproverBatchPayments = lazy(() => import('./pages/EppGeneralContractor/EGCApproverBatchPayments'));
const EGCFinalApproverBatchPayments = lazy(() => import('./pages/EppGeneralContractor/EGCFinalApproverBatchPayments'));
const EGCPaymentAnalyzer = lazy(() => import('./pages/EppGeneralContractor/PaymentAnalyzerFromERP'));
const EGCDJCConfiguration = lazy(() => import('./pages/EppGeneralContractor/EGCDJCConfig'));
const EGCSuppliers = lazy(() => import('./pages/EppGeneralContractor/EGCSuppliers'));
const EGCSupplierCreation = lazy(() => import('./pages/EppGeneralContractor/EGCSupplierCreation'));

const ESCDefault = lazy(() => import('./pages/EppSubContractor/ESCDefault'));
const ESCDashboard = lazy(() => import('./pages/EppSubContractor/ESCDashboard'));
const ESCHome = lazy(() => import('./pages/EppSubContractor/ESCHome'));
const ESCXPay = lazy(() => import('./pages/EppSubContractor/ESCXPay'));
const ESCProject = lazy(() => import('./pages/EppSubContractor/ESCProject'));
const ESCDocuments = lazy(() => import('./pages/EppSubContractor/ESCDocuments'));
const ESCXpeditedProcess = lazy(() => import('./pages/EppSubContractor/ESCXpeditedProcess'));
const ESCXpeditedAcceptTerms = lazy(() => import('./pages/EppSubContractor/ESCXpeditedAcceptTerms'));

export const getKeycloakClient = () => Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: 'struxtion',
  clientId: 'portal',
});

export const setAxiosInterceptor = (keycloakClient) => {
  axios.interceptors.request.use(async (req) => {
    await keycloakClient.updateToken(5);
    req.headers.Authorization = `Bearer ${keycloakClient.token}`;

    return req;
  });
};

export const loginToKeycloak = ({
  setKeycloak,
  setUser,
  setAuthError,
  trackAnalytics,
}) => {
  const keycloakClient = getKeycloakClient();

  keycloakClient.init({ onLoad: 'login-required' })
    .then((isAuthenticated) => {
      if (isAuthenticated) {
        setAxiosInterceptor(keycloakClient);

        const {
          tokenParsed: {
            struxtion: { userId, company, roles },
          },
        } = keycloakClient;

        axios.get(`/api/user/${userId}`)
          .then(({ data }) => {
            setUser({
              ...data,
              roles,
              companyId: company?.id,
              companyName: company?.name,
              companyType: companyTypes[company?.type?.toUpperCase()],
              actionsByRoles: formatRolActionsList(data?.struxtionRoles, company?.id),
              relatedCompanies: orderRelatedCompanies(data?.relatedCompanies),
            });

            if (trackAnalytics) {
              setGtagUser(userId);
              setGtagCompany(company?.id, company?.name);
            }

            setKeycloak({
              authenticated: true,
              logout: () => keycloakClient.logout({ redirectUri: window.origin }),
              refresh: () => keycloakClient.updateToken(99999999),
            });
          })
          .catch(() => {
            setKeycloak({
              logout: () => keycloakClient.logout({ redirectUri: window.origin }),
            });
            setAuthError(true);
          });
      } else {
        setAuthError(true);
      }
    })
    .catch(() => { setAuthError(true); });
};

export const Route = ({
  component: Component,
  layout: Layout,
  name,
  path,
  defaultPath,
  trackAnalytics,
  ...rest
}) => {
  const history = useHistory();

  if (path === '/getfunded') {
    const keycloakClient = getKeycloakClient();

    keycloakClient.init({ onLoad: 'check-sso' })
      .then((authenticated) => {
        if (!authenticated && trackAnalytics) {
          triggerGtagEvent('Registration Redirect', {
            event_callback: () => {
              keycloakClient.register({ redirectUri: window.location.origin });
            },
          });
        } else if (!authenticated) {
          keycloakClient.register({ redirectUri: window.location.origin });
        } else {
          history.push('/');
        }
      });
    return null;
  }

  if (trackAnalytics) {
    setGtagConfig(name || path, path);
  }

  return (
    <ReactRoute
      {...rest}
      path={path}
      render={(props) => (
        <Layout {...props} defaultPath={defaultPath}>
          <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
          </Suspense>
        </Layout>
      )}
    />
  );
};

export const PrivateRoute = ({
  component: Component,
  layout: Layout,
  restrictCompanyType,
  path,
  trackAnalytics,
  routePermission,
  ...rest
}) => {
  const [authError, setAuthError] = React.useState(false);
  const { setKeycloak, keycloak } = useKeycloak();
  const { user, setUser } = useUser();
  const [isXPayGC, setIsXPayGC] = React.useState(false);

  const history = useHistory();
  const authenticated = keycloak && keycloak.authenticated;

  React.useEffect(() => {
    if (!authenticated) {
      loginToKeycloak({
        setKeycloak,
        setUser,
        setAuthError,
        trackAnalytics,
      });
    }
  }, [setKeycloak, setUser, authenticated, trackAnalytics]);

  React.useEffect(() => {
    const checkPermissions = () => {
      const payorRoles = user.settings.find((setting) => setting.settingName === UserSettings.PAYOR_ROLES)?.settingValue.split(',').map(Number);
      const payeeRoles = user.settings.find((setting) => setting.settingName === UserSettings.PAYEE_ROLES)?.settingValue.split(',').map(Number);

      if (payorRoles && payeeRoles) {
        const hasPayorPermissions = user.struxtionRoles
          .filter((role) => role.companyId === user.companyId)
          .some((action) => payorRoles.includes(action.id));
        const hasPayeePermissions = user.struxtionRoles
          .filter((role) => role.companyId === user.companyId)
          .some((action) => payeeRoles.includes(action.id));
        return { payor: hasPayorPermissions, payee: hasPayeePermissions };
      }

      return { payor: false, payee: false };
    };

    if (authenticated) {
      const isStruxtionAdmin = user.companyType === companyTypes.STRUXTION;

      if (!isStruxtionAdmin) {
        let selectedCompanyType = loadSessionStorage(CURRENT_COMPANY_TYPE);

        if (selectedCompanyType) {
          const isMakePaymentsEnabled = user.makePaymentsStatusEnum === MakePaymentsStatusEnum.ENABLED;

          if (isMakePaymentsEnabled) {
            user.companyType = selectedCompanyType;
          }
        } else if (user.isPayor && user.isPayee && user.makePaymentsStatusEnum === MakePaymentsStatusEnum.ENABLED) {
          if (user.struxtionRoles) {
            const { payor, payee } = checkPermissions();
            if (payor && payee) {
              selectedCompanyType = user.companyType;
            } else if (payor) {
              selectedCompanyType = companyTypes.GENERAL_CONTRACTOR;
            } else if (payee) {
              selectedCompanyType = companyTypes.SUB_CONTRACTOR;
            }
          } else {
            selectedCompanyType = user.companyType;
          }
        } else {
          selectedCompanyType = user.companyType;
        }

        user.companyType = selectedCompanyType;

        setUser(user);

        setIsXPayGC(user.companyType === companyTypes.GENERAL_CONTRACTOR && user.isEppCompany);

        window.sessionStorage.setItem(CURRENT_COMPANY_TYPE, JSON.stringify(selectedCompanyType));
      }

      registrationRedirect(history, user, path);
      companyTypeRedirect(restrictCompanyType, history, user);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, user, history, restrictCompanyType, authenticated]);

  // Roles
  const userHasPermission = () => user?.struxtionRoles.filter((role) => role.companyId === user?.companyId).map((r) => r.name).some((role) => routePermission.includes(role));

  if (authenticated) {
    // Logic to redirect by path and object array roles
    if (isXPayGC && !path.includes('/notificationEvaluator')) {
      if (user?.roles.length > 0 && userHasPermission()) {
        return <Route component={Component} layout={Layout} path={path} trackAnalytics={trackAnalytics} {...rest} />;
      } return <AccessRestrictedError routePermission={routePermission} />;
    }
    return <Route component={Component} layout={Layout} path={path} trackAnalytics={trackAnalytics} {...rest} />;
  }

  return authError
    ? <AuthError keycloakClient={keycloak} />
    : <LoadingPage />;
};

export const SCLayout = (props) => <AppBarWithDrawer {...props} links={scDrawerLinks} />;
export const GCLayout = (props) => <AppBarWithDrawer {...props} links={gcDrawerLinks} />;
export const AdminLayout = (props) => <AppBarWithDrawer {...props} links={adminDrawerLinks} />;
export const EppGCLayout = (props) => <AppBarWithDrawer {...props} links={eppGCDrawerLinks} />;
export const EppSCLayout = (props) => <AppBarWithDrawer {...props} links={eppSCDrawerLinks} />;

const App = () => (
  <div>
    <Switch>
      {
        addRouteGroup(registrationRoot, { layout: Registration }, (
          <>
            <PrivateRoute exact path="/3" name="(reg) About Your Financials" component={AboutFinancials} />
            <PrivateRoute exact path="/5" name="(reg) About Your Projects" component={AboutProjects} />
            <PrivateRoute exact path="/4" name="(reg) Registration Confirmation" component={Confirmation} />
            <PrivateRoute exact path="/6" name="(reg) Upload Pay Apps" component={AboutYourCustomers} />
            <PrivateRoute exact path="/2" name="(reg) About Your Company" component={AboutCompany} />
            <PrivateRoute exact path="/1" name="(reg) About You" component={AboutYou} />
            <PrivateRoute path="" name="(reg) About You" component={AboutYou} />
          </>
        ))
      }
      {
        addRouteGroup(adminRoot, { layout: AdminLayout, restrictCompanyType: companyTypes.STRUXTION, trackAnalytics: false }, (
          <>
            <PrivateRoute path="/loan/:loanId/pay-app/:payAppId/detail" component={PayAppDetail} />
            <PrivateRoute path="/loan/:loanId/pay-app/:payAppId" component={PayAppProcessing} />
            <PrivateRoute path="/scs/:subContractorId/project/:projectId" component={ProjectDetails} />
            <PrivateRoute path="/scs/:subContractorId/projectCreation" component={ProjectCreation} />
            <PrivateRoute path="/loan/:loanId/projects" component={LoanDetailProjects} />
            <PrivateRoute path="/loan/:loanId/documents" component={LoanDetailDocuments} />
            <PrivateRoute path="/loan/:loanId/statements" component={LoanDetailStatements} />
            <PrivateRoute path="/loan/:loanId/payApps" component={LoanDetail} />
            <PrivateRoute path="/loans" component={Loans} />
            <PrivateRoute path="/projects" component={Projects} />
            <PrivateRoute path="/payments" component={Payments} />
            <PrivateRoute path="/struxtionAccounts" component={StruxtionBankAccounts} />
            <PrivateRoute path="/operatingAccounts" component={OperatingAccounts} />
            <PrivateRoute path="/bankAccount/:subContractorId/:accountType" component={StruxtionBankDetails} />
            <PrivateRoute path="/funding" component={StruxtionFunding} />
            <PrivateRoute path="/project/:projectId" name="(sc) Project Detail" component={AdminProjectDetails} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/user/:userId" component={UserProfile} />
            <PrivateRoute path="/scs/:subContractorId/bank-account" component={SCBankAccount} />
            <PrivateRoute path="/scs/:subContractorId" component={SCProfile} />
            <PrivateRoute path="/scCreation" component={CompanyCreation} />
            <PrivateRoute path="/gcs/:generalContractorId" component={GCProfile} />
            <PrivateRoute path="/companies" component={Companies} />
            <PrivateRoute path="/epp/:generalContractorId/projects/:projectId/djc/:paymentInstructionId/:paymentStatus/useAllProjects=:useAllProjects" name="(gc) DJC Configuration" component={EGCDJCConfiguration} />
            <PrivateRoute path="/epp/:generalContractorId/projects/:projectId/batchPayment" name="(gc) Batch Payment" component={EGCBatchPayment} />
            <PrivateRoute path="/epp/:generalContractorId/projects/batchPayment" name="(gc) Batch Payment" component={EGCBatchPayment} />
            <PrivateRoute path="/epp/projects/:generalContractorId/new" name="(admin) Project Creation" component={(props) => <EGCProjectCreation {...props} isFullyAccess />} />
            <PrivateRoute path="/epp/projects/:projectId/paymentInstruction" name="(gc) Payments Instruction creation" component={EGCPaymentInstructionCreation} />
            <PrivateRoute path="/epp/subContractors/associate/supplier" name="(gc) Subcontractors" component={EGCAssociateSubcontractors} />
            <PrivateRoute path="/epp/subContractors/associate" name="(gc) Subcontractors" component={EGCAssociateSubcontractors} />
            <PrivateRoute path="/epp/suppliers/:subContractorId/create/:projectId" name="(gc) subContractors detail" component={EGCSupplierCreation} />
            <PrivateRoute path="/epp/suppliers/:subContractorId/add/:projectId" name="(gc) subContractors detail" component={EGCSupplierCreation} />
            <PrivateRoute path="/epp/suppliers/:subContractorId/edit/:projectId" name="(gc) subContractors detail" component={EGCSupplierCreation} />
            <PrivateRoute path="/epp/:generalContractorId/projects/:projectId/sc/:subContractorId" name="(admin) Supplier Detail" component={EGCSuppliers} />
            <PrivateRoute path="/epp/:generalContractorId/projects/:projectId" name="(admin) Project Detail" component={EGCProjectDetail} />
            <PrivateRoute path="/epp/:subContractorId/bankAccount/:accountType" component={StruxtionBankDetails} />
            <PrivateRoute path="/epp/:subContractorId/bank-account" component={SCBankAccount} />
            <PrivateRoute path="/epp/:companyId/edit" name="(gc) Edit company profile" component={EGCEditCompanyProfile} />
            <PrivateRoute path="/epp/:companyId" component={EGCDetail} />
            <PrivateRoute path="/epp" component={EPPs} />
            <PrivateRoute path="/eppGc/:companyId/sc/:subContractorId/edit" component={EGCEditSc} />
            <PrivateRoute path="/eppGc/:companyId/sc/:subContractorId/invite" component={EGCEditSc} />
            <PrivateRoute path="/eppGc/:companyId/create" component={EGCSubcontractorCreation} />
            <PrivateRoute path="/eppGc/:companyId/associate/:subContractorId" name="(gc) Sub Contractor associate" component={SCAssociatedDetail} />
            <PrivateRoute path="/eppGc/:companyId/associate" name="(gc) Subcontractors" component={EGCAssociateSubcontractors} />
            <PrivateRoute path="/eppGc/:companyId/sc/:subContractorId" component={SCProfile} />
            <PrivateRoute path="/gcAccounts/:generalContractorId/bank-account" component={GCBankAccDetails} />
            <PrivateRoute path="/gcAccounts" component={GCAcccounts} />
            <PrivateRoute path="/revenueAccount" component={SRAAccount} />
            <PrivateRoute path="/xda/:companyId" component={XDADetail} />
            <PrivateRoute path="/xda" component={XDAccounts} />
            <PrivateRoute path="/detailSubcontractor/:subContractorId" component={DetailSubcontractorXpay} />
            <PrivateRoute path="" component={Default} />
          </>
        ))
      }
      {
        addRouteGroup(eppGC, { layout: EppGCLayout, restrictCompanyType: companyTypes.GENERAL_CONTRACTOR, trackAnalytics: false }, (
          <>
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.DASHBOARD} path="/dashboard" name="(gc) EPP" component={EGCDashboard} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.STRX_ACCOUNT} path="/struxtionAccount/create" name="(gc) Bank Account" component={EGCBankAccount} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.STRX_ACCOUNT} path="/struxtionAccount" name="(gc) Struxtion Account" component={EGCStruxtionAccount} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.OP_ACCOUNT} path="/operatingAccount" name="(gc) Operating Account" component={OperatingAccount} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.DASHBOARD} path="/companyProfile/edit" name="(gc) Edit company profile" component={EGCEditCompanyProfile} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.DASHBOARD} path="/companyProfile" name="(gc) Company profile" component={EGCProfile} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.DOCUMENTS} path="/documents" name="(gc) Documents" component={EGCDocuments} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/batchPayment/:paymentId/approve" name="(gc) Approve Batch Payments" component={EGCApproverBatchPayments} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/batchPayment/finalApproval" name="(gc) Approve Batch Payments" component={EGCFinalApproverBatchPayments} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/payments/djc/:paymentInstructionId/:paymentStatus/useAllProjects=:useAllProjects" name="(gc) DJC Configuration" component={EGCDJCConfiguration} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PAYMENTS} path="/payments/analyzer" name="(gc) Payments Instruction" component={EGCPaymentAnalyzer} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PAYMENTS} path="/payments" name="(gc) Payments Instruction" component={EGCPayments} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects/new" name="(gc) Project Creation" component={(props) => <EGCProjectCreation {...props} />} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects/:projectId/djc/:paymentInstructionId/:paymentStatus/useAllProjects=:useAllProjects" name="(gc) DJC Configuration" component={EGCDJCConfiguration} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects/:projectId/batchPayment" name="(gc) Batch Payment" component={EGCBatchPayment} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects/batchPayment" name="(gc) Batch Payment" component={EGCBatchPayment} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects/:projectId/paymentInstruction" name="(gc) Payments Instruction creation" component={EGCPaymentInstructionCreation} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects/paymentInstruction/:location" name="(gc) Payments Instruction creation" component={EGCPaymentInstructionCreation} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects/:projectId" name="(gc) Project Detail" component={EGCProjectDetail} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.PROJECTS} path="/projects" name="(gc) Projects" component={EGCProjects} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors/associate/new" name="(gc) Sub Contractor creation" component={EGCSubcontractorCreation} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors/associate/supplier" name="(gc) Subcontractors" component={EGCAssociateSubcontractors} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors/associate/:subContractorId" name="(gc) Sub Contractor associate" component={SCAssociatedDetail} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors/edit/:subContractorId" name="(gc) subContractors edit" component={EGCEditSc} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors/:subContractorId/invite" name="(gc) Invite company" component={EGCEditSc} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors/associate" name="(gc) Subcontractors" component={EGCAssociateSubcontractors} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors/:subContractorId" name="(gc) subContractors detail" component={EGCScDetail} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/subContractors" name="(gc) subContractors" component={EGCSubcontractor} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/suppliers/:subContractorId/create/:projectId" name="(gc) subContractors detail" component={EGCSupplierCreation} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/suppliers/:subContractorId/add/:projectId" name="(gc) subContractors detail" component={EGCSupplierCreation} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/suppliers/:subContractorId/edit/:projectId" name="(gc) subContractors detail" component={EGCSupplierCreation} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.COMPANIES} path="/suppliers/:subContractorId/detail/:projectId" name="(gc) subContractors detail" component={EGCSuppliers} />
            <PrivateRoute routePermission={NAV_ROLES.XPAY_GC.DASHBOARD} path="/" component={EGCDefault} />
          </>
        ))
      }
      {
        addRouteGroup(eppSC, { layout: EppSCLayout, restrictCompanyType: companyTypes.SUB_CONTRACTOR, trackAnalytics: false }, (
          <>
            <PrivateRoute path="/companyProfile/paymentMethod" name="(sc) EPP" component={(props) => <OperatingAccount {...props} isSwitchPaymentMethod />} />
            <PrivateRoute path="/home" name="(sc) EPP" component={ESCHome} />
            <PrivateRoute path="/companyProfile/edit" name="(sc) edit company profile" component={EGCEditCompanyProfile} />
            <PrivateRoute path="/companyProfile" name="(sc) company profile" component={EGCProfile} />
            <PrivateRoute path="/dashboard" name="(sc) EPP" component={ESCDashboard} />
            <PrivateRoute path="/payments/requestadjustment" name="(sc) request adjustments" component={RequestAdjuments} />
            <PrivateRoute path="/payments" name="(sc) EPP" component={EGCPayments} />
            <PrivateRoute path="/documents" name="(sc) Documents" component={ESCDocuments} />
            <PrivateRoute path="/operatingAccount" name="(sc) Operating Account" component={OperatingAccount} />
            <PrivateRoute path="/scXPay/detail/:supplierToCompany/:projectId" name="(sc) subContractors XPay" component={ESCXPay} />
            <PrivateRoute path="/scXPay/detail/:projectId" name="(sc) subContractors XPay" component={ESCXPay} />
            <PrivateRoute path="/scXPay" name="(sc) Projects XPay" component={ESCProject} />
            <PrivateRoute path="/lien-waiver/:paymentId/:projectId" name="(sc) Lien Waiver" component={CheckLienWaiver} />
            <PrivateRoute path="/xpay-plus-contract/:projectId" name="(sc) XPay Plus contract" component={XPayPlusContract} />
            <PrivateRoute path="/processPaidEarly/acceptConditions/:paymentInstructionId" name="(sc) Accept terms to process paid early" component={ESCXpeditedAcceptTerms} />
            <PrivateRoute path="/processPaidEarly/:paymentInstructionId" name="(sc) Process paid early" component={ESCXpeditedProcess} />
            <PrivateRoute path="/" component={ESCDefault} />
          </>
        ))
      }
      {
        addRouteGroup(gcRoot, { layout: GCLayout, restrictCompanyType: companyTypes.GENERAL_CONTRACTOR, trackAnalytics: false }, (
          <>
            <PrivateRoute path="/subContractor/:subContractorId/pay-app/:payAppId" name="(gc) subContractor details" component={GcScPayAppDetail} />
            <PrivateRoute path="/subContractor/:subContractorId" name="(gc) subContractor details" component={GcScDetail} />
            <PrivateRoute path="/dashboard" name="(gc) Dashboard" component={GCDashboard} />
            <PrivateRoute path="" component={GcDefault} />
          </>
        ))
      }
      {
        addRouteGroup(scRoot, { layout: SCLayout, restrictCompanyType: companyTypes.SUB_CONTRACTOR }, (
          <>
            <PrivateRoute path="/projectCreation" name="(sc) Project Creation" component={ProjectCreation} />
            <PrivateRoute path="/project/:projectId" name="(sc) Project Detail" component={ProjectDetails} />
            <PrivateRoute path="/bank-account" name="(sc) Bank Account" component={BankAccount} />
            <PrivateRoute path="/operating-account" name="(sc) Operating Account" component={OperatingAccount} />
            <PrivateRoute path="/home/scProjects" name="(sc) Projects" component={ScProjectsTab} />
            <PrivateRoute path="/home/documents" name="(sc) Documents" component={Documents} />
            <PrivateRoute path="/home/statements" name="(sc) Statements" component={Statements} />
            <PrivateRoute path="/home/payApps" name="(sc) Dashboard" component={Dashboard} />
            <PrivateRoute path="/pay-app-ml/:payAppId" name="(sc) Project mechanics lean" component={CheckMl} />
            <PrivateRoute path="/closing-document" name="(sc) Closing Document" component={CheckClosingDocuments} />
            <PrivateRoute path="/application" name="(sc) Loan Application" component={Application} />
            <PrivateRoute path="/companyProfile" name="(sc) company profile" component={CompanyProfile} />
            <PrivateRoute path="" component={ScDefault} />
          </>
        ))
      }
      {
        addRouteGroup('/legal', { layout: Legal }, (
          <>
            <Route path="/privacy-policy" name="Privacy Policy" component={PrivacyPolicy} />
            <Route path="/terms-of-use" name="Terms of Use" component={TermsOfUse} />
            <Route path="/electronic-communications-agreement" name="Struxtion Electronic Communications Agreement" component={ElectronicAgreement} />
            <Route path="/solid-platform-terms" name="SolidFi Platform Terms of Use" component={SolidPlatformTerms} />
            <Route path="/solid-privacy-policy" name="SolidFi Privacy Policy" component={SolidPrivacyPolicy} />
            <Route path="/solid-website-dashboard-terms" name="SolidFi Website &amp; Dashboard Terms of Use" component={SolidWebsite} />
            <Route path="/lcb-electronic-communications-disclosures-and-agreement" name="Electronic Communications Disclosures And Agreement" component={LCBElectronicAgreement} />
            <Route path="/usa-patriot-act-notice" name="USA Patriot Act notice" component={USAPatriotActNotice} />
            <Route path="/lcb-business-account-agreement" name="LCB Business Account Agreement" component={LCBBusinessAccountAgreement} />
          </>
        ))
      }
      <Route path="/getfunded" name="Get Funded" component={() => null} layout={AppBarWithoutDrawer} trackAnalytics />
      <PrivateRoute path="/notificationEvaluator" component={NotificationEvaluatorComponent} restrictCompanyType="notificationEvaluatorRedirect" layout={AppBarWithoutDrawer} />
      <PrivateRoute path="/login" component={() => null} restrictCompanyType="redirect" layout={AppBarWithoutDrawer} />
      <PrivateRoute exact path="/" component={() => null} restrictCompanyType="redirect" layout={AppBarWithoutDrawer} />
      <Route path="*" component={NotFound} name="(err) Not Found" layout={AppBarWithoutDrawer} />
    </Switch>
  </div>
);

Route.defaultProps = {
  defaultPath: '/',
  path: '/',
  name: null,
  layout: ({ children }) => children,
  trackAnalytics: true,
};

Route.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  defaultPath: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
  trackAnalytics: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  component: () => null,
  layout: () => null,
  restrictCompanyType: undefined,
  path: undefined,
  trackAnalytics: true,
  routePermission: [],
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  layout: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  restrictCompanyType: PropTypes.string,
  path: PropTypes.string,
  trackAnalytics: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  routePermission: PropTypes.array,
};

export default App;
