import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  LinearProgress, Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, FormControl, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useSwitchCompany from '../../api/useSwitchCompany';
import { useUser } from '../../contexts/UserContext';
import { orderCompaniesByName } from '../../utils';
import { companyTypes } from '../../constants';
import { eppGC, eppSC } from '../../constants/navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '500px',
    maxWidth: '100%',
  },
  order: {
    backgroundColor: theme.palette.background.paper,
  },
  mainCompanyAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const SwitchCompanyModal = ({
  open, setModal,
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const isStruxtionAdmin = user.companyType === companyTypes.STRUXTION_ADMIN;
  const isXPayGC = user.companyType === companyTypes.GENERAL_CONTRACTOR;

  const [newOrderCompanies, setNewOrderCompanies] = useState([]);
  const [value, setValue] = React.useState(user.companyId);

  const {
    loading,
    loaded,
    error,
    getContactCompaniesOrder,
    getCompanyInfo,
  } = useSwitchCompany();

  useEffect(() => {
    getContactCompaniesOrder(user.contactId, (data) => {
      setNewOrderCompanies(orderCompaniesByName(data));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setModal(false);
  };

  const redirectAfterSwitch = () => {
    window.sessionStorage.clear();
    let redirectPath = '/dashboard';
    if (isStruxtionAdmin) {
      redirectPath = '/admin/dashboard';
    } else if (isXPayGC) {
      redirectPath = `${eppGC}/dashboard`;
    } else {
      redirectPath = `${eppSC}/dashboard`;
    }
    window.location.href = redirectPath;
  };

  const handleConfirm = () => {
    getCompanyInfo(user.id, value, () => redirectAfterSwitch());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="offered-dialog-title"
    >
      <DialogTitle id="offered-dialog-title">Switch company</DialogTitle>
      <DialogContent>
        {error && <Alert severity="warning">{error}</Alert>}
        <Grid item xs={12}>
          <div>
            <Typography>
              All Companies
            </Typography>
            {loading && <LinearProgress />}
            {
              loaded && newOrderCompanies.length && (
              <FormControl className={classes.root}>
                <RadioGroup aria-label="switch-company" name="selectedCompany" value={value} onChange={handleChange}>
                  {newOrderCompanies.map((company) => (
                    <FormControlLabel key={company.companyId} value={company.companyId} control={<Radio />} label={company.companyName} />
                  ))}
                </RadioGroup>
              </FormControl>
              )
            }
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          onClick={handleConfirm}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
        <Button
          disableElevation
          onClick={handleClose}
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SwitchCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default SwitchCompanyModal;
