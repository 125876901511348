import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useUser } from '../../contexts/UserContext';
import ConfirmSwitchCompanyModal from '../Multicompany/ConfirmSwitchCompanyModal';
import { REDIRECT_TO, SWITCH_TO_COMPANY_ID } from '../../constants';

const NotificationEvaluatorComponent = () => {
  const location = useLocation();
  const { user: { companyId, relatedCompanies } } = useUser();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const [isOpenConfirmSwitchComp, setIsOpenConfirmSwitchComp] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    if (urlParams && urlParams.size > 0) {
      const switchToCompanyId = urlParams.get(SWITCH_TO_COMPANY_ID);
      if (switchToCompanyId) {
        urlParams.delete(SWITCH_TO_COMPANY_ID);
        const switchToCompany = relatedCompanies.find((company) => company.id === switchToCompanyId);
        const redirectToUri = urlParams.get(REDIRECT_TO) || '/dashboard';

        if (companyId === switchToCompanyId) {
          window.location.href = decodeURI(redirectToUri);
          return;
        }

        setSelectedCompany({ name: switchToCompany.name, id: switchToCompany.id, companyType: switchToCompany.companyType });
        setRedirectTo(decodeURI(redirectToUri));
        setIsOpenConfirmSwitchComp(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  return (
    <>
      { isOpenConfirmSwitchComp && <ConfirmSwitchCompanyModal company={selectedCompany} open={isOpenConfirmSwitchComp} setModal={setIsOpenConfirmSwitchComp} customReload reloadRoute={redirectTo} /> }
    </>
  );
};

export default NotificationEvaluatorComponent;
