/* eslint-disable */

export const Application = {
  black: require('./AppBarIcons/loanApp-gray.svg'),
  grad: require('./AppBarIcons/loanApp-colour.svg'),
  white: require('./AppBarIcons/loanApp-green.svg'),
};

export const BankAcct = {
  black: require('./AppBarIcons/bank-gray.svg'),
  grad: require('./AppBarIcons/bank-colour.svg'),
  white: require('./AppBarIcons/bank-green.svg'),
};

export const Bldg = {
  black: require('./bldg-black.svg'),
  grad: require('./bldg-grad.svg'),
  white: require('./bldg-white.svg'),
  width: '95%',
};

export const Cost = {
  black: require('./cost-black.svg'),
  grad: require('./cost-grad.svg'),
  white: require('./cost-white.svg'),
  width: '85%',
};

export const Devices = {
  black: require('./devices-black.svg'),
  grad: require('./devices-grad.svg'),
  white: require('./devices-white.svg'),
};

export const Deal = {
  black: require('./deal.svg'),
  grad: require('./deal.svg'),
  white: require('./deal.svg'),
};

export const Document = {
  black: require('./AppBarIcons/document-gray.svg'),
  grad: require('./AppBarIcons/document-colour.svg'),
  white: require('./AppBarIcons/document-green.svg'),
};
export const Funding = {
  black: require('./AppBarIcons/funding-gray.svg'),
  grad: require('./AppBarIcons/funding-colour.svg'),
  white: require('./AppBarIcons/funding-green.svg'),
};
export const FundingFaster = {
  black: require('./funds-faster-black.svg'),
  grad: require('./funds-faster-grad.svg'),
  white: require('./funds-faster-white.svg'),
};

export const Hardhat = {
  black: require('./hardhat-black.svg'),
  grad: require('./hardhat-grad.svg'),
  white: require('./hardhat-white.svg'),
};

export const HigherMargin = {
  black: require('./higher-margin-black.svg'),
  grad: require('./higher-margin-grad.svg'),
  white: require('./higher-margin-white.svg'),
};

export const Home = {
  black: require('./AppBarIcons/home-gray.svg'),
  grad: require('./AppBarIcons/home-colour.svg'),
  white: require('./AppBarIcons/home-green.svg'),
};

export const LowerRisk = {
  black: require('./lower-risk-black.svg'),
  grad: require('./lower-risk-grad.svg'),
  white: require('./lower-risk-white.svg'),
};
export const Membership = {
  black: require('./membership.svg'),
  grad: require('./membership.svg'),
  white: require('./membership.svg'),
};
export const Office = {
  black: require('./AppBarIcons/office-gray.svg'),
  grad: require('./AppBarIcons/office-colour.svg'),
  white: require('./AppBarIcons/office-green.svg'),
};
export const OperatingAcct = {
  black: require('./AppBarIcons/loanApp-gray.svg'),
  grad: require('./AppBarIcons/loanApp-colour.svg'),
  white: require('./AppBarIcons/loanApp-green.svg'),
};
export const Person = {
  black: require('./person-black.svg'),
  grad: require('./person-grad.svg'),
  white: require('./person-white.svg'),
};

export const ProfitUp = {
  black: require('./profit-up-black.svg'),
  grad: require('./profit-up-grad.svg'),
  white: require('./profit-up-white.svg'),
};

export const Subcontractor = {
  black: require('./subcontractor-black.svg'),
  grad: require('./subcontractor-grad.svg'),
  white: require('./subcontractor-white.svg'),
};

export const Ui = {
  black: require('./AppBarIcons/ui-gray.svg'),
  grad: require('./AppBarIcons/ui-colour.svg'),
  white: require('./AppBarIcons/ui-green.svg'),
};

export const UpfrontPricing = {
  black: require('./upfront-pricing-black.svg'),
  grad: require('./upfront-pricing-grad.svg'),
  white: require('./upfront-pricing-white.svg'),
};

export const User = {
  black: require('./AppBarIcons/user-gray.svg'),
  grad: require('./AppBarIcons/user-colour.svg'),
  white: require('./AppBarIcons/user-green.svg'),
};
export const Users = {
  black: require('./AppBarIcons/users-gray.svg'),
  grad: require('./AppBarIcons/users-colour.svg'),
  white: require('./AppBarIcons/users-green.svg'),
};
export const Wallet = {
  black: require('./wallet.svg'),
  grad: require('./wallet.svg'),
  white: require('./wallet.svg'),
};
export const Worker = {
  black: require('./AppBarIcons/worker-gray.svg'),
  grad: require('./AppBarIcons/worker-colour.svg'),
  white: require('./AppBarIcons/worker-green.svg'),
};

export const Xlogo = {
  black: require('./xpay-logo.svg'),
  grad: require('./xpay-logo.svg'),
  white: require('./xpay-logo.svg'),
};

export const Xicon = {
  black: require('./x-icon.svg'),
  grad: require('./x-icon.svg'),
  white: require('./x-icon.svg'),
};

export const iconDocument = {
  black: require('./AppBarIcons/icon-doc.svg'),
  grad: require('./AppBarIcons/icon-doc-selected.svg'),
  white: require('./AppBarIcons/icon-doc-selected.svg'),
};

export const circleIcon = {
  black: require('./check_circle_outline.svg'),
  grad: require('./check_circle_outline.svg'),
  white: require('./check_circle_outline.svg'),
};

export const XpayPlus = {
  black: require('./icon-xpay+.svg'),
  grad:  require('./icon-xpay+.svg'),
  white:  require('./icon-xpay+.svg'),
};

export const Xpay = {
  black: require('./xpay.svg'),
  grad:  require('./xpay.svg'),
  white:  require('./xpay.svg'),
};

export const bulkUpload = {
  black: require('./bulk_upload.svg'),
  grad:  require('./bulk_upload.svg'),
  white:  require('./bulk_upload.svg'),
};

export const addSubcontractor = {
  black: require('./add_subcontractor.svg'),
  grad:  require('./add_subcontractor.svg'),
  white:  require('./add_subcontractor.svg'),
};

export const Sign = {
  white:  require('./sign.svg'),
};

export const LienWaiverAssigner= {
  black: require('./lien-waiver-signer.svg'),
  grad: require('./lien-waiver-signer.svg'),
  white: require('./lien-waiver-signer.svg'),
};

export const SupplierManager = {
  black: require('./cogs.svg'),
  grad: require('./cogs.svg'),
  white: require('./cogs.svg'),
};

export const PayorPayeeMain = {
  black: require('./payor-payee-main.png'),
  grad: require('./payor-payee-main.png'),
  white: require('./payor-payee-main.png'),
  width: '200px',
};

export const PayorPayeeMakePayments = {
  black: require('./payor-payee-make-payments.svg'),
  grad: require('./payor-payee-make-payments.svg'),
  white: require('./payor-payee-make-payments.svg'),
  width: '200px',
  borderRadius: '20px',
};

export const PayeeAdminRole = {
  black: require('./payee-admin-role.svg'),
  grad: require('./payee-admin-role.svg'),
  white: require('./payee-admin-role.svg'),
};

export const PayeeLWSRole = {
  black: require('./payee-lws-role.svg'),
  grad: require('./payee-lws-role.svg'),
  white: require('./payee-lws-role.svg'),
};

export const OutgoingPayments = {
  black: require('./outgoing-payments.svg'),
  grad: require('./outgoing-payments.svg'),
  white: require('./outgoing-payments.svg'),
};

export const IncomingPayments = {
  black: require('./incoming-payments.svg'),
  grad: require('./incoming-payments.svg'),
  white: require('./incoming-payments.svg'),
};
