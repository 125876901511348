import { companyTypes } from '../constants';
import {
  adminRoot, scRoot, gcRoot, eppGC, eppSC, registrationRoot,
} from '../constants/navigation';

const companyTypeRedirect = (restrictCompanyType, history, user) => {
  if (!restrictCompanyType || user.companyType === restrictCompanyType) return;

  if (restrictCompanyType === 'notificationEvaluatorRedirect') return;

  if (user.companyType === companyTypes.STRUXTION) {
    history.push(adminRoot);
  } else if (user.companyType === companyTypes.GENERAL_CONTRACTOR && user.isEppCompany) {
    history.push(eppGC);
  } else if (user.companyType === companyTypes.SUB_CONTRACTOR && user.isEppCompany) {
    history.push(eppSC);
  } else if (user.companyType === companyTypes.GENERAL_CONTRACTOR && !user.isEppCompany) {
    history.push(gcRoot);
  } else if (user.companyType === companyTypes.SUB_CONTRACTOR) {
    history.push(scRoot);
  } else if (!user.companyType) {
    history.push(registrationRoot);
  } else {
    history.push('invalid-company');
  }
};

export default companyTypeRedirect;
